import AddIcon from "@mui/icons-material/Add";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton, Tooltip } from "@mui/material";
import cx from "classnames";
import { React, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as cartActions from "../../store/cart/actionTypes";
import { StoreContext } from "../../store/store";
import styles from "./BestSaleCard.module.css";

const BestSaleCard = ({ item }) => {
  const [state, dispatch] = useContext(StoreContext);
  const navigate = useNavigate();
  const { userState, cartState } = state;
  const [itemCartState, setItemCartState] = useState(null);

  const handleIncrement = (item) => {
    const subtotal = cartState.subtotal + item.price1;
    dispatch({
      type: cartActions.UPDATE_ITEM_ON_BASKET,
      update: 1,
      id: item.id,
      subtotal: subtotal,
    });
  };

  const handleDecrement = (item) => {
    const subtotal = cartState.subtotal - item.price1;

    dispatch({
      type: cartActions.UPDATE_ITEM_ON_BASKET,
      update: -1,
      id: item.id,
      subtotal: subtotal,
    });
  };

  const productExists = (item) => {
    const isInCart = cartState.items.filter((itm) => itm.id === item.id);
    if (isInCart.length) return true;
    return false;
  };

  const addItem = (item) => {
    const subtotal = cartState.subtotal + item.price1;
    if (!productExists(item)) {
      item = {
        ...item,
        purchasedUnits: 1,
      };
      dispatch({
        type: cartActions.ADD_NEW_ITEM_TO_BASKET,
        item: item,
        subtotal: subtotal,
      });
    } else {
      dispatch({
        type: cartActions.UPDATE_ITEM_ON_BASKET,
        update: 1,
        id: item.id,
        subtotal: subtotal,
      });
    }
  };

  const removeItem = (item) => {
    const updatedUnits = cartState.items.filter((itm) => itm.id === item.id)[0]
      .purchasedUnits;
    const subtotal = cartState.subtotal - item.price1 * updatedUnits;
    dispatch({
      type: cartActions.REMOVE_ITEM,
      id: item.id,
      subtotal: subtotal,
    });
  };

  useEffect(() => {
    const isInCart = cartState.items.find((itm) => itm.id === item.id);
    setItemCartState(isInCart);
  }, [cartState]);
  return (
    <div className={styles.content} key={item.title}>
      <div className={styles.imageContainer}>
        <img
          src={item?.image}
          alt="image"
          className={styles.image}
          no-referreal
          loading="lazy"
        />
      </div>
      <div className={styles.titleContainer}>
        <div>
          <div className={styles.category}>{item.category}</div>
          <div
            className={styles.title}
            onClick={() => navigate(`/all-products/${item.id}`)}
          >
            {" "}
            {item.title}
          </div>
        </div>
        <div className={styles.priceContainer}>
          <div className={styles.price}>Rs.{item.price1}</div>
          {!itemCartState ? (
            item.status !== 1 ? (
              <Tooltip title="Out of Stock">
                <div className={cx(styles.cart, styles.outOfStock)}>
                  <AddShoppingCartIcon />
                </div>
              </Tooltip>
            ) : (
              <div
                className={styles.cart}
                onClick={(event) => {
                  event.stopPropagation();
                  addItem(item);
                }}
                disabled={item.status !== 1}
              >
                <AddShoppingCartIcon />
              </div>
            )
          ) : (
            <div className={styles.cartBtn}>
              <IconButton
                className={cx(styles.iconBtn, styles.removeIcon)}
                onClick={() => {
                  if (itemCartState.purchasedUnits > 1) {
                    handleDecrement(item);
                  } else {
                    removeItem(item);
                  }
                }}
              >
                <RemoveIcon fontSize="12px" />
              </IconButton>
              <p className={styles.cartNumber}>
                x{itemCartState.purchasedUnits}
              </p>
              <IconButton
                className={cx(styles.iconBtn, styles.addIcon)}
                onClick={() => {
                  handleIncrement(item);
                }}
              >
                <AddIcon fontSize="12px" />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default BestSaleCard;
