import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Uploader from "../../components/Uploader/Uploader";
import { cardCheckout } from "../../services/api/checkout";
import { StoreContext } from "../../store/store";
import styles from "./PaymentModal.module.css";

const PaymentModal = ({ open, onClose, billingData, paymentData }) => {
  const [state, dispatch] = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const { cartState } = state;
  const [photo, setPhoto] = useState(null);
  const [photoError, setPhotoError] = useState(false);
  const navigate = useNavigate();

  const handleFormSubmit = () => {
    if (!photo) {
      setPhotoError(true);
      return;
    }

    let formData = {
      name: billingData?.name,
      phone: paymentData?.phone_number,
      house: billingData?.house_number,
      street_address: billingData?.street_address,
      city: billingData?.city,
      orders: JSON.stringify(
        cartState.items.map(({ id, purchasedUnits }) => ({
          id: id,
          quantity: purchasedUnits,
        }))
      ),
      imageUrl: photo,
      preferred_time: billingData?.delivery_time,
      email: paymentData?.email,
    };

    cardCheckout(formData)
      .then((res) => {
        navigate(`/checkout/success?order_id=${res.data.unique_order_id}`);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.data) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Order Failed");
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <Dialog onClose={onClose} open={open}>
        <Box className={styles.container}>
          <div className={styles.wrapper}>
            <Box>
              <Box>
                <Typography sx={{ mb: "15px" }} fontSize="18px">
                  Payment Information
                </Typography>
                <form noValidate autoComplete="off">
                  <div className={styles.wrapper}>
                    <img
                      src="/images/qr.jpg"
                      alt="qr"
                      loading="lazy"
                      className={styles.image}
                    />
                    <Uploader
                      photo={photo}
                      setPhoto={setPhoto}
                      error={photoError}
                      setError={setPhotoError}
                    />
                  </div>
                </form>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                gap="20px"
                m="30px auto"
              >
                <Button
                  style={{ position: "relative" }}
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  onClick={() => {
                    handleFormSubmit();
                  }}
                  sx={{
                    boxShadow: "none",
                    color: "white",
                    borderRadius: 0,
                    padding: "15px 40px",
                  }}
                >
                  Place Order
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#52C41A",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Button>
              </Box>
            </Box>
          </div>
        </Box>
      </Dialog>
    </div>
  );
};

export default PaymentModal;
