import { baseRequest } from "./base";

const submitSupport = async (data) => {
  try {
    // Create a new FormData object
    const formData = new FormData();

    // Convert boolean value to number
    data.bought_from_us = data.bought_from_us ? 1 : 0;

    // Append each key-value pair to the FormData
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    // Make the API call with the FormData
    const response = await baseRequest({
      url: `/api/support`,
      method: "POST",
      body: formData,
      contentType: "multipart/form-data",
    });

    return response;
  } catch (error) {
    console.error("Error in support:", error);
    throw error;
  }
};

export { submitSupport };
