import Skeleton from "@mui/material/Skeleton";
import React from "react";
import styles from "./ProductCard.module.css";

function ProductSkeleton() {
  return (
    <div className={styles.productCard}>
      <div className={styles.cardBanner}>
        <Skeleton variant="rectangular" width="100%" height="160px">
          <div />
        </Skeleton>
      </div>
      <div className={styles.content}>
        <h3>
          <Skeleton width="100%" />
        </h3>
        <div>
          <Skeleton width="80%" />
        </div>
        <div>
          <Skeleton width="60%" />
        </div>

        <div>
          <Skeleton height="20" width="30%" />
        </div>
      </div>
    </div>
  );
}

export default ProductSkeleton;
