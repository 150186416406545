import React, { createContext, useReducer } from "react";
import { cartReducer, cartInitialState } from "./cart/reducer";
import { userReducer, userInitialState } from "./user/reducer";
import { homeReducer, homeInitialState } from "./home/reducer";

const StoreContext = createContext({});

const combineReducers =
  (...reducers) =>
  (state, action) =>
    reducers.reduce((newState, reducer) => reducer(newState, action), state);

const initialState = {
  cartState: cartInitialState,
  userState: userInitialState,
  homeState: homeInitialState,
};

const rootReducer = combineReducers(cartReducer, userReducer, homeReducer);

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  // Important(!): memoize array value. Else all context consumers update on *every* render
  const store = React.useMemo(() => [state, dispatch], [state]);

  return <StoreContext.Provider value={store}> {children} </StoreContext.Provider>;
};

export { StateProvider, StoreContext };
