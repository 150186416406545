import { Box, Button, CircularProgress, Typography } from "@mui/material";
import cx from "classnames";
import React, { useContext, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import styles from "./Payment.module.css";
import { StoreContext } from "../../store/store";
import Uploader from "../../components/Uploader/Uploader";
import { cardCheckout } from "../../services/api/checkout";
import { toast } from "react-toastify";

const Payment = () => {
  const [state, dispatch] = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const { cartState } = state;
  const [photo, setPhoto] = useState(null);
  const [photoError, setPhotoError] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();

  const handleFormSubmit = () => {
    if (photo.length === 0) {
      setPhotoError(true);
      return;
    }

    let formData = {
      name: location?.state?.billingData?.name,
      phone: location?.state?.paymentData?.phone_number,
      house_number: location?.state?.billingData?.house_number,
      street_address: location?.state?.billingData?.street_address,
      landmark: location?.state?.billingData?.landmark,
      city: location?.state?.billingData?.city,
      prefecture: location?.state?.billingData?.prefecture,
      orders: JSON.stringify(
        cartState.items.map(({ id, purchasedUnits }) => ({
          id: id,
          quantity: purchasedUnits,
        }))
      ),
      imageUrl: photo,
    };

    cardCheckout(formData)
      .then((res) => {
        navigate(`/checkout/success?order_id=${res.data.unique_order_id}`);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.data) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Order Failed");
        }
        setLoading(false);
      });
  };

  return cartState.items.length === 0 ? (
    <Navigate to="/" />
  ) : (
    <Box
      width="80%"
      m="60px auto"
      className={cx(styles.container, "container")}
    >
      <div className={styles.wrapper}>
        <Box>
          <Box m="30px auto">
            <Box>
              <Typography sx={{ mb: "15px" }} fontSize="18px">
                Payment Information
              </Typography>
              <form noValidate autoComplete="off">
                <div className={styles.wrapper}>
                  <img
                    src="/images/qr.png"
                    alt="qr"
                    loading="lazy"
                    className={styles.image}
                  />
                  <Uploader
                    photo={photo}
                    setPhoto={setPhoto}
                    error={photoError}
                    setError={setPhotoError}
                  />
                </div>
              </form>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            gap="20px"
            m="30px auto"
          >
            <Button
              style={{ position: "relative" }}
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              disabled={loading}
              onClick={() => {
                handleFormSubmit();
              }}
              sx={{
                boxShadow: "none",
                color: "white",
                borderRadius: 0,
                padding: "15px 40px",
              }}
            >
              Place Order
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#52C41A",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default Payment;
