import AddIcon from "@mui/icons-material/Add";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton } from "@mui/material";
import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getProductById,
  getRelatedProducts,
} from "../../services/api/products";
import * as cartActions from "../../store/cart/actionTypes";
import { StoreContext } from "../../store/store";
import { converter } from "../../utils/htmlConverter/htmlConverter";
import { CustomBreadcrumb } from "../Breadcrumb";
import ProductSkeleton from "../Product/ProductSkeleton";
import ProductCard from "../ProductCard/ProductCard";
import styles from "./ProductDetails.module.css";
import ProductDetailsSkeleton from "./ProductDetailsSkeleton";

function ProductDetails() {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [itemCartState, setItemCartState] = useState(null);
  const [state, dispatch] = useContext(StoreContext);
  const { userState, cartState } = state;
  const [loading, setLoading] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [mainImageIndex, setMainImageIndex] = useState(0);

  const handleThumbnailClick = (index) => {
    setMainImageIndex(index);
  };

  const handleIncrement = (item) => {
    if (Object.keys(item).length === 0) return;

    const subtotal = cartState.subtotal + item.price1;
    dispatch({
      type: cartActions.UPDATE_ITEM_ON_BASKET,
      update: 1,
      id: item.id,
      subtotal: subtotal,
    });
  };

  const handleDecrement = (item) => {
    if (Object.keys(item).length === 0) return;

    const subtotal = cartState.subtotal - item.price1;
    dispatch({
      type: cartActions.UPDATE_ITEM_ON_BASKET,
      update: -1,
      id: item.id,
      subtotal: subtotal,
    });
  };

  const productExists = (item) => {
    if (Object.keys(item).length === 0) return false;

    const isInCart = cartState.items.filter((itm) => itm.id === item.id);
    if (isInCart.length) return true;
    return false;
  };

  const addItem = (item) => {
    if (Object.keys(item).length === 0) return;

    const subtotal = cartState.subtotal + item.price1;
    if (!productExists(item)) {
      item = {
        ...item,
        purchasedUnits: 1,
      };
      dispatch({
        type: cartActions.ADD_NEW_ITEM_TO_BASKET,
        item: item,
        subtotal: subtotal,
      });
    } else {
      dispatch({
        type: cartActions.UPDATE_ITEM_ON_BASKET,
        update: 1,
        id: item.id,
        subtotal: subtotal,
      });
    }
  };

  const removeItem = (item) => {
    if (Object.keys(item).length === 0) return;

    const updatedUnits = cartState.items.filter((itm) => itm.id === item.id)[0]
      .purchasedUnits;
    const subtotal = cartState.subtotal - item.price1 * updatedUnits;
    dispatch({
      type: cartActions.REMOVE_ITEM,
      id: item.id,
      subtotal: subtotal,
    });
  };

  useEffect(() => {
    let abort = new AbortController();
    setLoading(true);
    getProductById(id, abort.signal)
      .then((res) => {
        setProduct(res.data);
        setLoading(false);
        const subCategory = res.data.product_sub_category;
        const subCategoryId = subCategory ? subCategory.id : null;
        const subCategoryTitle = subCategory ? subCategory.title : null;
        let breadcrumbItems = [
          { title: "Home", to: "/" },
          { title: "Products", to: "/all-products/" },
          {
            title: res.data.product_categories.title,
            to: `/all-products?category_id=${res.data.product_categories.id}&title=${res.data.product_categories.title}`,
          },
          subCategory && {
            title: subCategory.title,
            to: `/all-products?category_id=${res.data.product_categories.id}&title=${res.data.product_categories.title}&product_sub_categories_id=${subCategoryId}&sub_category_title=${subCategoryTitle}`,
          },
          { title: res.data.title, to: null },
        ].filter(Boolean);
        setBreadcrumb(breadcrumbItems);
      })
      .catch((err) => {
        setLoading(false);
      });

    return () => {
      abort.abort();
    };
  }, [id]);

  useEffect(() => {
    let abort = new AbortController();
    setLoading(true);
    getRelatedProducts(id, abort.signal)
      .then((res) => {
        setRelatedProducts(res.data.recommended_products.slice(0, 3));
      })
      .catch((err) => {})
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 1000)
      );

    return () => {
      abort.abort();
    };
  }, [id]);

  useEffect(() => {
    const isInCart = cartState.items.find((itm) => itm.id === product.id);
    setItemCartState(isInCart);
  }, [cartState, product]);

  return loading ? (
    <div>
      <ProductDetailsSkeleton />
    </div>
  ) : (
    <div className={styles.container}>
      <div className="container">
        <CustomBreadcrumb items={breadcrumb} />
        <div className={styles.row}>
          <div className={styles.wrapper}>
            <div className={styles.productDetailsImg}>
              <div className={styles.productDisplay}>
                <img
                  src={product?.product_images?.[mainImageIndex]?.image}
                  loading="lazy"
                  alt={`image-${mainImageIndex + 1}`}
                  className={cx(styles.w100, styles.mainImage)}
                />
              </div>
            </div>
            <ul className={styles.productThumbnailList}>
              {product?.product_images &&
                product?.product_images.slice(0, 5).map((image, index) => (
                  <li
                    key={index}
                    className={cx(
                      styles.productThumbnailItem,
                      mainImageIndex === index ? styles.imageActive : null
                    )}
                    onClick={() => handleThumbnailClick(index)}
                  >
                    <img
                      src={image?.image}
                      loading="lazy"
                      alt={`thumbnail-${index + 1}`}
                      className={cx(styles.w100, styles.image)}
                    />
                  </li>
                ))}
            </ul>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.content}>
              <div className={styles.itemCategory}>
                {product?.product_categories?.title}
              </div>
              <h1 className={styles.itemTitle}>{product?.title}</h1>
              <p
                className={styles.itemSubtitle}
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(product?.description),
                }}
              ></p>

              <div className={styles.itemAvailability}>
                Availability:{" "}
                <span
                  style={
                    product?.status === 1
                      ? { color: "var(--success)" }
                      : { color: "#ff000d" }
                  }
                >
                  {product?.status === 1 ? "In stock" : "Out of Stock"}
                </span>
              </div>
              <p className={styles.itemPrice}>Rs. {product?.price1}.00</p>
              <a
                className={styles.specification}
                href={product?.specification_url}
                target="_blank"
              >
                See full specification here{" "}
                <KeyboardArrowRightIcon className={styles.icon} />
              </a>
            </div>
            <div className={styles.footer}>
              {itemCartState ? (
                <div className={styles.cartBtn}>
                  <IconButton
                    className={cx(styles.iconBtn, styles.removeIcon)}
                    onClick={() => {
                      if (itemCartState.purchasedUnits > 1) {
                        handleDecrement(product);
                      } else {
                        removeItem(product);
                      }
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <p className={styles.cartNumber}>
                    x{itemCartState.purchasedUnits}
                  </p>
                  <IconButton
                    className={cx(styles.iconBtn, styles.addIcon)}
                    onClick={() => {
                      handleIncrement(product);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              ) : (
                <button
                  className={cx(
                    "btn",
                    styles.btn,
                    product.status !== 1 && styles.outOfStock
                  )}
                  onClick={() => {
                    addItem(product);
                  }}
                  disabled={product.status !== 1}
                >
                  <AddShoppingCartIcon className={styles.cartIcon} />
                  {product.status !== 1 ? "Out Of Stock" : "Add to Cart"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.containerFluid}>
        <div className="container">
          <div className={styles.headerContainer}>
            <h2 className={styles.title}>Related Products</h2>
          </div>

          {loading ? (
            <div className={cx(styles.topProductList, "grid-list")}>
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
            </div>
          ) : (
            <div className={cx(styles.topProductList, "grid-list")}>
              {relatedProducts.map((item) => (
                <ProductCard item={item} key={item.id} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
