import cx from "classnames";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { StoreContext } from "../../store/store";
import styles from "./Footer.module.css";

function Footer() {
  const [state, dispatch] = useContext(StoreContext);
  const { homeState } = state;
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.slogan}>
        Power Up Your Productivity with Our Products
      </div>

      <footer className={styles.footer}>
        <div className={styles.footerTop}>
          <div className={cx(styles.container, "container")}>
            <div className={styles.footerBrand}>
              <div className={styles.logo}>
                <img src={"/images/logo-1.png"} alt="" width={140} />
              </div>
              <ul className={styles.socialList}>
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100044940260328"
                    target="_blank"
                    className={styles.socialLink}
                  >
                    <ion-icon name="logo-facebook"></ion-icon>
                  </a>
                </li>
                <li>
                  <a href="#" className={styles.socialLink}>
                    <ion-icon name="logo-instagram"></ion-icon>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank" className={styles.socialLink}>
                    <ion-icon name="logo-tiktok"></ion-icon>
                  </a>
                </li>
              </ul>

              <ul className={styles.footerList}>
                <li>
                  <a className={styles.footerDetail}>
                    <div className={styles.icon}>
                      <ion-icon name="location"></ion-icon>
                    </div>
                    Newroad (Bhatbhateni Gate) Pokhara, Nepal
                  </a>
                </li>
                <li>
                  <a href="tel:061-583301" className={styles.footerDetail}>
                    <div className={styles.icon}>
                      <ion-icon name="call"></ion-icon>
                    </div>
                    061–583301, 53302, 553312
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:hoasales4@gmail.com"
                    target="_blank"
                    className={styles.footerDetail}
                  >
                    <div className={styles.icon}>
                      <ion-icon name="mail"></ion-icon>
                    </div>
                    hoasales4@gmail.com
                  </a>
                </li>
              </ul>
            </div>
            <ul className={styles.footerList}>
              <li>
                <p className={styles.footerListTitle}>Company</p>
              </li>
              <li>
                <Link to="/policies/privacy/" className={styles.footerLink}>
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/policies/delivery/" className={styles.footerLink}>
                  Delivery Policy
                </Link>
              </li>
              <li>
                <Link to="/policies/refund/" className={styles.footerLink}>
                  Refund Policy
                </Link>
              </li>
              <li>
                <Link to="/policies/terms/" className={styles.footerLink}>
                  Terms & Conditions
                </Link>
              </li>
            </ul>
            <div className={styles.footerProduct}>
              <p className={styles.footerListTitle}>Products</p>
              <ul className={cx(styles.footerList, styles.footerCategoryList)}>
                {homeState.categories.slice(0,10).map((item) => (
                  <li
                    key={item.id}
                    onClick={() => {
                      navigate(
                        `/all-products?title=${item.title}&category_id=${item.id}`
                      );
                      window.scroll(0, 0);
                    }}
                  >
                    <p className={styles.footerLink}>{item.title}</p>
                  </li>
                ))}
              </ul>
            </div>
            <ul className={styles.footerList}>
              <li>
                <p className={styles.footerListTitle}>Find Us Here</p>
              </li>
              <div className={styles.location}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14062.2315576023!2d83.9869616!3d28.2207419!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3995944ef873f0fb%3A0x8d987e5353c0cb70!2sHimalayan%20Computers!5e0!3m2!1sen!2snp!4v1701936772429!5m2!1sen!2snp"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </ul>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <div className={cx(styles.container, "container")}>
            <p className={styles.copyright}>
              &copy;2023 Himalayan Computers. All Rights Reserved.
            </p>
            <p className={styles.poweredBy}>
              Powered By:
              <span>
                <a href="https://twoacesolutions.com/" target="_blank">
                  Two Ace Solutions Pvt. Ltd.
                </a>
              </span>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
