import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import styles from "./Support.module.css";
import { useState } from "react";
import { submitSupport } from "../../services/api/support";
import Uploader from "../../components/Uploader/Uploader";

const Support = () => {
  const [data, setData] = useState({
    contact_person: "",
    bought_from_us: true,
    phone: "",
    product: "",
    problem: "",
    address: "",
    service_type: "",
  });
  const [photo, setPhoto] = useState(null);
  const [photoError, setPhotoError] = useState(false);
  const [delivered, setDelivered] = useState("");
  const [error, setError] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    if (data.bought_from_us) {
      if (!photo) {
        setPhotoError(true);
        return;
      }
    }
    var err = {};
    if (data.contact_person === "") {
      err["contact_person"] = "Contact Person is required";
      setDelivered("");
    }

    if (data.bought_from_us === "") {
      err["bought_from_us"] = "You need to tick the field";
      setDelivered("");
    }

    if (data.service_type === "") {
      err["service_type"] = "Service Type is required";
      setDelivered("");
    }

    if (data.phone === "") {
      setDelivered("");
      err["phone"] = "Phone number is required";
    } else if (data.phone.trim().length < 10 || data.phone.trim().length > 10) {
      setDelivered("");
      err["phone"] = "Phone number must be 10 digits";
    }

    if (data.product === "") {
      setDelivered("");
      err["product"] = "Product is required";
    }

    if (data.problem === "") {
      setDelivered("");
      err["problem"] = "Problem is required";
    }
    if (data.address === "") {
      setDelivered("");
      err["address"] = "Address is required";
    }

    const isValidated = Object.keys(err).length === 0;
    setError(err);
    if (isValidated) {
      let formData = { ...data, image_url: photo };
      console.log(data.bought_from_us);
      submitSupport(formData)
        .then((res) => {
          setData({
            contact_person: "",
            bought_from_us: true,
            phone: "",
            product: "",
            problem: "",
            address: "",
            service_type: "",
          });
          setPhoto(null);
          setDelivered(
            "We appreciate you for reaching out. We received your message. We will get back to you by next working day."
          );
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className="container">
          <h1 className={styles.mainTitle}>Himalayan Computers Tech Support</h1>
          <div className={styles.contactContainer}>
            <div className={styles.contact}>
              <div className={styles.contactTitle}>Contact Us</div>
              <div className={styles.contactSubtitle}>
                Welcome to Himalayan Computers Tech Support, your trusted
                partner for all your technology needs. We are dedicated to
                providing exceptional service and support to our valued
                customers. Whether you've purchased from us or not, our team is
                here to assist you with any inquiries, technical issues, or
                service requests.
              </div>
              <div className={styles.intro}>
                <div className={styles.subTitle}>
                  <ion-icon name="mail"></ion-icon>
                  hoasales4@gmail.com
                </div>
                <div className={styles.subTitle}>
                  <ion-icon name="location"></ion-icon> Newroad (Bhatbhateni
                  Gate) Pokhara, Nepal
                </div>
                <div className={styles.subTitle}>
                  <ion-icon name="call"></ion-icon>
                  061–583301, 53302, 553312
                </div>
              </div>
            </div>
            <form
              className={styles.form}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div className={styles.formTitle}>Fill up your form here</div>
              <div className={styles.radio}>
                <FormControl
                  error={Boolean(error.bought_from_us)}
                  component="fieldset"
                >
                  <FormLabel className={styles.label}>
                    Did you buy from us?
                  </FormLabel>
                  <RadioGroup
                    row
                    defaultValue={true} // Set the default value to true or false based on your requirement
                    onChange={(e) =>
                      setData({
                        ...data,
                        bought_from_us: e.target.value === "true",
                      })
                    }
                    value={data.bought_from_us.toString()}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  {error.bought_from_us && (
                    <FormHelperText>{error.bought_from_us}</FormHelperText>
                  )}
                </FormControl>
                {data.bought_from_us && (
                  <div className={styles.uploader}>
                    <Uploader
                      photo={photo}
                      setPhoto={setPhoto}
                      error={photoError}
                      setError={setPhotoError}
                      icon={false}
                      title="Upload the screenshot of your bill"
                    />
                  </div>
                )}
              </div>
              <div className={styles.radio}>
                <FormControl
                  error={Boolean(error.service_type)}
                  component="fieldset"
                >
                  <FormLabel className={styles.label}>Service Type</FormLabel>
                  <RadioGroup
                    row
                    onChange={(e) =>
                      setData({ ...data, service_type: e.target.value })
                    }
                    value={data.service_type}
                  >
                    <FormControlLabel
                      value="Warranty"
                      control={<Radio />}
                      label="Warranty"
                    />
                    <FormControlLabel
                      value="Service Warranty"
                      control={<Radio />}
                      label="Service Warranty"
                    />
                    <FormControlLabel
                      value="AMC"
                      control={<Radio />}
                      label="AMC"
                    />
                    <FormControlLabel
                      value="To Be Billed"
                      control={<Radio />}
                      label="To Be Billed"
                    />
                  </RadioGroup>
                  {error.service_type && (
                    <FormHelperText>{error.service_type}</FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className={styles.gridContainer}>
                <TextField
                  label={<div className={styles.label}>Address</div>}
                  className={styles.input}
                  value={data.address}
                  onChange={(e) =>
                    setData({ ...data, address: e.target.value })
                  }
                  fullWidth
                  error={error.address}
                  helperText={error.address ? error.address : ""}
                ></TextField>
                <TextField
                  label={<div className={styles.label}>Contact Person</div>}
                  value={data.contact_person}
                  onChange={(e) =>
                    setData({ ...data, contact_person: e.target.value })
                  }
                  fullWidth
                  error={error.contact_person}
                  helperText={error.contact_person ? error.contact_person : ""}
                ></TextField>
                <TextField
                  label={<div className={styles.label}>Phone Number</div>}
                  value={data.phone}
                  onChange={(e) => setData({ ...data, phone: e.target.value })}
                  fullWidth
                  error={error.phone}
                  helperText={error.phone ? error.phone : ""}
                ></TextField>

                <TextField
                  label={<div className={styles.label}>Product</div>}
                  value={data.product}
                  onChange={(e) =>
                    setData({ ...data, product: e.target.value })
                  }
                  fullWidth
                  error={error.product}
                  helperText={error.product ? error.product : ""}
                ></TextField>
                <TextField
                  label={<div className={styles.label}>Problem</div>}
                  value={data.problem}
                  onChange={(e) =>
                    setData({ ...data, problem: e.target.value })
                  }
                  fullWidth
                  rows={4}
                  multiline
                  error={error.problem}
                  helperText={error.problem ? error.problem : ""}
                ></TextField>
              </div>
              <Button variant="outlined" type="submit" className={styles.btn}>
                Submit
              </Button>

              <div
                className={styles.delivered}
                style={delivered ? { display: "block" } : null}
              >
                {delivered}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={styles.map}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3516.0104529968826!2d83.99470423974395!3d28.206995003378157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3995951dde328795%3A0xe580f33ca1bc6f6b!2sTwo%20Ace%20Solutions%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1695970763525!5m2!1sen!2snp"
          height={400}
          style={{ marginBottom: "0", paddingBottom: "0" }}
          width="100%"
          title="map"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </>
  );
};

export default Support;
