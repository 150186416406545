import { Skeleton } from "@mui/material";
import { React } from "react";
import styles from "./ProductDetails.module.css";

const ProductDetailsSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className="container">
        <div className={styles.row}>
          <div className={styles.wrapper}>
            <div className={styles.productDetailsImg}>
              <Skeleton variant="rectangular" width="100%" height="360px">
                <div />
              </Skeleton>
            </div>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.content}>
              <p>
                <Skeleton width="20%" />
              </p>
              <h1>
                <Skeleton width="100%" />
              </h1>
              <p>
                <Skeleton width="80%" />
              </p>
              <p>
                <Skeleton width="70%" />
              </p>
              <div>
                <Skeleton width="20%" />
              </div>
              <h2>
                <Skeleton width="50%" />
              </h2>
              <h4>
                <Skeleton width="52%" />
              </h4>
            </div>
            <div>
              <Skeleton height="20" width="30%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsSkeleton;
