import React, { useContext } from "react";
import styles from "./ShopFlex.module.css";
import cx from "classnames";
import { StoreContext } from "../../store/store";
import { useNavigate } from "react-router-dom";
const ShopFlex = () => {
  const [state] = useContext(StoreContext);
  const { homeState } = state;
  const navigate = useNavigate();
  return (
    <div className={styles.containerFluid}>
      {homeState.categories.slice(0, 1).map((item) => (
        <div className="container" key={item.id}>
          <div className={styles.bgImage}>
            <div className={styles.shopContainer}>
              <div className={styles.shop}>
                <div className={styles.title}>
                  shop and <strong>save big</strong> on {item.title}
                </div>
                <div className={styles.buyCard}>
                  <button
                    className={cx("btn", styles.data)}
                    onClick={() =>
                      navigate(
                        `/all-products?title=${item.title}&category_id=${item.id}`
                      )
                    }
                  >
                    Shop now
                    {/* <div className={styles.price}>
                    <sup>$</sup>79
                    <sup>99</sup>
                  </div> */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ShopFlex;
