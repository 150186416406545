import { Alert, AlertTitle, Box } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import { checkoutDisplay } from "../../services/api/checkout";
import * as cartActions from "../../store/cart/actionTypes";
import { StoreContext } from "../../store/store";

function CheckoutSuccess() {
  const [state, dispatch] = useContext(StoreContext);
  const { userState } = state;
  const navigate = useNavigate();
  const resetCart = () => {
    dispatch({ type: cartActions.RESET_BASKET });
  };

  const handleLinkExpired = () => {
    //navigate to orders or expired page
    navigate("/orders/");
  };

  useEffect(() => {
    let abort = new AbortController();

    if (userState.isLoggedIn) {
      checkoutDisplay(query.get("order_id"), abort.signal)
        .then(() => {
          resetCart();
        })
        .catch((err) => {
          if (err.response && err.response.status === 410) {
            handleLinkExpired();
          }
        });
    }

    return () => {
      abort.abort();
    };
  }, [userState.isLoggedIn]);

  const query = useQuery();
  return (
    <div style={{ paddingTop: "120px" }}>
      <Box m="90px auto" width="80%" height="50vh" className="container">
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          You have successfully made an order{" "}
          <strong>{query.get("order_id")}</strong>.
          <strong> Thanks for purchasing our products.</strong>
        </Alert>
      </Box>
    </div>
  );
}

export default CheckoutSuccess;
