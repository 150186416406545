import React, { useContext, useState } from "react";
import styles from "./FixedCart.module.css";
import Cart from "../Cart/Cart";
import cx from "classnames";
import { StoreContext } from "../../store/store";
import { useLocation } from "react-router";

function FixedCart() {
  const [state, dispatch] = useContext(StoreContext);
  const [cartActive, setCartActive] = useState(false);
  const { userState, cartState, homeState } = state;
  const location = useLocation();
  const { pathname } = location;

  return !pathname.includes("checkout") ? (
    <div>
      <div className={styles.container} onClick={() => setCartActive(true)}>
        <div className={styles.actionBtn}>
          <ion-icon name="bag-handle-outline"></ion-icon>
          {cartState.quantity > 0 && <data className={styles.btnBadge}>{cartState.quantity}</data>}
        </div>
      </div>
      <div
        className={cx(styles.overlay, cartActive && styles.overlayActive)}
        onClick={() => setCartActive(false)}
      ></div>
      <Cart setCartActive={setCartActive} cartActive={cartActive} />
    </div>
  ) : (
    ""
  );
}

export default FixedCart;
