import React from "react";
import styles from "./FeatureCard.module.css";
import { useNavigate } from "react-router-dom";

const FeatureCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.content}
      key={data.title}
      onClick={() => navigate(`/all-products/${data.id}`)}
    >
      <div className={styles.imageContainer}>
        <img
          src={data?.image}
          alt="image"
          className={styles.image}
          no-referreal
          loading="lazy"
        />
      </div>
      <div className={styles.titleContainer}>
        <div>
          <div className={styles.title}> {data.title}</div>
        </div>
        <div className={styles.priceContainer}>
          <div className={styles.price}>Rs.{data.price1}</div>
        </div>
      </div>
    </div>
  );
};
export default FeatureCard;
