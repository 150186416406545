import { baseRequest } from "./base";

const getProductCategories = async (signal) => {
  var response = await baseRequest({
    url: "/api/product-category",
    method: "GET",
    signal: signal,
  });
  return response;
};

const getProducts = async (
  categoryId,
  subCategoryId = "",
  page,
  signal,
  search = "",
  min_price = "",
  max_price = "",
  brandId = "",
  order_by = ""
) => {
  var response = await baseRequest({
    url: `/api/products?category_id=${categoryId}&product_sub_categories_id=${subCategoryId}&brand_id=${[
      brandId,
    ]}&min_price=${min_price}&max_price=${max_price}&order_by=${order_by}&page=${page}&search=${search}`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const getRelatedProducts = async (id, signal) => {
  var response = await baseRequest({
    url: `/api/product/${id}/similar`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const getProductById = async (id, signal) => {
  var response = await baseRequest({
    url: `/api/product/${id}`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const getFeaturedProducts = async (signal) => {
  var response = await baseRequest({
    url: `/api/featured/products`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const getBestSellerProducts = async (signal) => {
  var response = await baseRequest({
    url: `/api/best-seller/products`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const getRecentProducts = async (signal) => {
  var response = await baseRequest({
    url: `/api/recent/products`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const getOnSaleProducts = async (signal) => {
  var response = await baseRequest({
    url: `/api/on-sale/products`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const getDeliveryCharge = async (orders, prefecture, signal) => {
  var postApi = "";
  for (let index = 0; index < orders.length; index++) {
    postApi += `orders[${index}][id]=${orders[index].id}&orders[${index}][quantity]=${orders[index].purchasedUnits}`;
    if (index + 1 < orders.length) {
      postApi = postApi + "&";
    }
  }
  postApi += `&prefecture=${prefecture}`;
  var response = await baseRequest({
    url: `/api/delivery-charge?${postApi}`,
    method: "GET",
    signal: signal,
  });
  return response;
};

export {
  getProductCategories,
  getProducts,
  getFeaturedProducts,
  getBestSellerProducts,
  getDeliveryCharge,
  getRecentProducts,
  getProductById,
  getRelatedProducts,
  getOnSaleProducts,
};
