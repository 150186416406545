import React, { useEffect, useState } from "react";
import BestSaleCard from "../../components/BestSaleCard/BestSaleCard";
import styles from "./BestSale.module.css";
import { getBestSellerProducts } from "../../services/api/products";

const BestSale = () => {
  const [bestSellProducts, setBestSellProducts] = useState([]);

  const [loading, setLoading] = useState(false);
  const fetchBestSellProducts = async (signal) => {
    setLoading(true);
    await getBestSellerProducts(signal)
      .then((res) => {
        setLoading(false);
        setBestSellProducts(res.data.products);
      })
      .catch((err) => {})
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 1000)
      );
  };

  useEffect(() => {
    let abort = new AbortController();
    fetchBestSellProducts(abort.signal);
    return () => {
      abort.abort();
    };
  }, []);
  return loading ? (
    <div>Loading...</div>
  ) : (
    <div className={styles.containerFluid}>
      <div className="container">
        <div className={styles.heading}>BestSellers</div>
        <div className={styles.cardContainer}>
          {bestSellProducts.map((data, index) => (
            <BestSaleCard item={data} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BestSale;
