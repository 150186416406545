import React from "react";
import BestSale from "../../sections/BestSale";
import FeatureProduct from "../../sections/FeatureCard";
import FlashSale from "../../sections/FlashSale";
import { Landing } from "../../sections/Landing";
import Products from "../../sections/Products/Products";
import ServiceCard from "../../sections/ServiceCard";
import ShopFlex from "../../sections/ShopFlex/ShopFlex";
function Home() {
  return (
    <div>
      <Landing />
      <FlashSale />
      <Products />
      <ShopFlex />
      <BestSale />
      <ServiceCard />
      <FeatureProduct />
    </div>
  );
}

export default Home;
