import { baseRequest } from "./base";

const syncCart = async (cartState) => {
  var response = await baseRequest({
    url: "/api/sync-cart",
    method: "POST",
    body: {
      quantity: cartState.quantity,
      items: cartState.items,
    },
  });
  return response;
};

export { syncCart };
