import React, { useEffect, useState } from "react";
import FeatureCard from "../../components/FeatureCard/FeatureCard";
import {
  getFeaturedProducts,
  getOnSaleProducts,
  getRecentProducts,
} from "../../services/api/products";
import styles from "./FeatureCard.module.css";

const FeatureProduct = () => {
  const [featuredProduct, setFeaturedProduct] = useState([]);
  const [onsaleProduct, setOnsaleProduct] = useState([]);
  const [recentProduct, setRecentProduct] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchFeaturedProducts = async (signal) => {
    setLoading(true);
    await getFeaturedProducts(signal)
      .then((res) => {
        setLoading(false);
        setFeaturedProduct(res.data.products);
      })
      .catch((err) => {})
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 1000)
      );
  };

  const fetchOnsaleProducts = async (signal) => {
    setLoading(true);
    await getOnSaleProducts(signal)
      .then((res) => {
        setLoading(false);
        setOnsaleProduct(res.data.products);
      })
      .catch((err) => {})
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 1000)
      );
  };

  const fetchRecentProducts = async (signal) => {
    setLoading(true);
    await getRecentProducts(signal)
      .then((res) => {
        setLoading(false);
        setRecentProduct(res.data.products);
      })
      .catch((err) => {})
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 1000)
      );
  };

  useEffect(() => {
    let abort = new AbortController();
    fetchFeaturedProducts(abort.signal);
    fetchRecentProducts(abort.signal);
    fetchOnsaleProducts(abort.signal);
    return () => {
      abort.abort();
    };
  }, []);
  return loading ? (
    <div>Loading...</div>
  ) : (
    <div className="container">
      <div className={styles.containerFluid}>
        <div className={styles.card}>
          {/* feature products */}
          <div className={styles.featureCard}>
            <div className={styles.heading}>Feature Products</div>
            <div className={styles.cardContainer}>
              {featuredProduct.slice(0, 5).map((data, index) => (
                <FeatureCard data={data} key={index} />
              ))}
            </div>
          </div>
          {/* OnSale products  */}
          <div className={styles.featureCard}>
            <div className={styles.heading}>Onsale Products</div>
            <div className={styles.cardContainer}>
              {onsaleProduct.slice(0, 5).map((data, index) => (
                <FeatureCard data={data} key={index} />
              ))}
            </div>
          </div>
          {/* Top products  */}
          <div className={styles.featureCard}>
            <div className={styles.heading}>Recent Products</div>
            <div className={styles.cardContainer}>
              {recentProduct.slice(0, 5).map((data, index) => (
                <FeatureCard data={data} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureProduct;
