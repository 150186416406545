import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import cx from "classnames";
import { isBefore, isToday } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import CODModal from "../../components/CODModal/CODModal";
import PaymentModal from "../../components/PaymentModal/PaymentModal";
import { syncCart } from "../../services/api/cart";
import * as cartActions from "../../store/cart/actionTypes";
import { StoreContext } from "../../store/store";
import styles from "./Checkout.module.css";

const deliveryTimes = [
  "Anytime",
  "9 to 12",
  "12 to 2",
  "2 to 4",
  "4 to 6",
  "6 to 8",
  "8 to 9",
];

const Checkout = () => {
  const [state, dispatch] = useContext(StoreContext);
  const { cartState } = state;
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [codModalOpen, setCodModalOpen] = useState(false);

  const [billingData, setBillingData] = useState({
    name: "",
    street_address: "",
    house_number: "",
    landmark: "",
    city: "",
    prefecture: "",
    delivery_time: deliveryTimes[0],
    delivery_date: null,
    paymentOption: null,
    email: "",
    terms: false,
  });

  const [paymentData, setPaymentData] = useState({
    phone_number: "",
    payment_option: "",
  });

  const handleClose = () => {
    setPaymentModalOpen(false);
  };
  const handleCodModalClose = () => {
    setCodModalOpen(false);
  };

  const handleFormSubmit = (e) => {
    let err = {};
    if (billingData.name.length === 0) {
      err["name"] = ["Name is required"];
    }
    if (billingData.street_address.length === 0) {
      err["street_address"] = ["Street Address name is required"];
    }
    if (billingData.house_number.length === 0) {
      err["house_number"] = ["House number is required"];
    }
    if (billingData.city.length === 0) {
      err["city"] = ["City is required"];
    }
    if (!billingData.terms) {
      err["terms"] = [
        "Please agree to the Terms and Conditions before proceeding.",
      ];
    }

    if (billingData.delivery_time.length === 0) {
      err["delivery_time"] = ["Delivery time is required"];
    }
    if (!billingData.delivery_date || !billingData.delivery_date.$d) {
      err["delivery_date"] = ["Delivery Date is required"];
    } else {
      const enteredDate = billingData.delivery_date.$d;
      if (!enteredDate || isNaN(enteredDate.getTime())) {
        err["delivery_date"] = [
          "Invalid date format. Please enter a valid date.",
        ];
      } else if (isBefore(enteredDate, new Date()) && !isToday(enteredDate)) {
        err["delivery_date"] = ["Selected date must be today or later."];
      }
    }
    if (paymentData.phone_number.length === 0) {
      err["phone_number"] = ["Phone number is required"];
    } else if (
      paymentData.phone_number.length < 10 ||
      paymentData.phone_number.length > 11
    ) {
      err["phone_number"] = ["Phone number is not valid"];
    }

    if (paymentData.payment_option.length === 0) {
      err["payment_option"] = ["Select a payment option"];
    }

    setErrors(err);
    if (Object.keys(err).length > 0) {
      return;
    }

    if (paymentData.payment_option === "card-payment") {
      setPaymentModalOpen(true);
    } else if (paymentData.payment_option === "cod") {
      setCodModalOpen(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (cartState.quantity > 0) {
      syncCart(cartState)
        .then((res) => {
          dispatch({
            type: cartActions.SYNC_CART,
            items: res.data.items,
            subtotal: res.data.subtotal,
          });
          setLoading(false);
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    }
  }, []);
  return cartState.items.length === 0 ? (
    <Navigate to="/" />
  ) : (
    <Box
      width="80%"
      m="60px auto"
      className={cx(styles.container, "container")}
    >
      <Box>
        <Box m="30px auto">
          {/* BILLING FORM */}
          <Box>
            <Typography sx={{ mb: "15px" }} fontSize="18px">
              Billing Information
            </Typography>
            <form noValidate autoComplete="off">
              <Box
                display="grid"
                gap="15px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  type="text"
                  label="Name"
                  value={billingData.name}
                  onChange={(e) =>
                    setBillingData({ ...billingData, name: e.target.value })
                  }
                  error={errors["name"] !== undefined}
                  helperText={errors["name"]}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  type="text"
                  label="Phone number"
                  value={paymentData.phone_number}
                  onChange={(e) => {
                    const regex = /^[0-9\b]+$/; // Only allow numbers
                    let val = e.target.value;
                    if (regex.test(val) || val === "") {
                      setPaymentData({ ...paymentData, phone_number: val });
                    }
                  }}
                  error={errors["phone_number"] !== undefined}
                  helperText={errors["phone_number"]}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  type="email"
                  label="Email (Optional)"
                  value={paymentData.email}
                  onChange={(e) => {
                    setPaymentData({ ...paymentData, email: e.target.value });
                  }}
                  error={errors["email"] !== undefined}
                  helperText={errors["email"]}
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>
              <Typography sx={{ mb: "15px", mt: "15px" }} fontSize="18px">
                Shipping Information
              </Typography>
              <Box
                display="grid"
                gap="15px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <>
                  <TextField
                    fullWidth
                    type="text"
                    label="House Number"
                    value={billingData.house_number}
                    onChange={(e) =>
                      setBillingData({
                        ...billingData,
                        house_number: e.target.value,
                      })
                    }
                    error={errors["house_number"] !== undefined}
                    helperText={errors["house_number"]}
                    sx={{ gridColumn: "span 2" }}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Street Address"
                    value={billingData.street_address}
                    onChange={(e) =>
                      setBillingData({
                        ...billingData,
                        street_address: e.target.value,
                      })
                    }
                    error={errors["street_address"] !== undefined}
                    helperText={errors["street_address"]}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="City"
                    value={billingData.city}
                    onChange={(e) =>
                      setBillingData({ ...billingData, city: e.target.value })
                    }
                    error={errors["city"] !== undefined}
                    helperText={errors["city"]}
                    sx={{ gridColumn: "span 2" }}
                  />
                </>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Preferred Date for Delivery"
                    onChange={(value) =>
                      setBillingData({ ...billingData, delivery_date: value })
                    }
                    value={billingData.delivery_date}
                    disablePast
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        sx={{ gridColumn: "span 2" }}
                        error={errors["delivery_date"] !== undefined}
                        helperText={errors["delivery_date"]}
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormControl
                  sx={{ gridColumn: "span 2" }}
                  error={errors["delivery_time"] !== undefined}
                >
                  <InputLabel id="demo-multiple-name-label">
                    Delivery Time
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    helperText={errors["delivery_time"]}
                    value={billingData.delivery_time}
                    onChange={(e) => {
                      setBillingData({
                        ...billingData,
                        delivery_time: e.target.value,
                      });
                    }}
                    input={<OutlinedInput label="Delivery Time" />}
                    // MenuProps={MenuProps}
                  >
                    {deliveryTimes.map((time, index) => (
                      <MenuItem key={index} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors["delivery_time"] && (
                    <FormHelperText id="my-helper-text">
                      {errors["delivery_time"]}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Typography sx={{ mb: "15px", mt: "15px" }} fontSize="18px">
                Payment Option
              </Typography>
              <Box
                display="grid"
                gap="15px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <FormControl
                  error={errors["payment_option"] !== undefined}
                  sx={{ gridColumn: "span 2" }}
                >
                  <RadioGroup
                    aria-labelledby="payment-option"
                    defaultValue="female"
                    name="payment-option"
                    onChange={(e) => {
                      setPaymentData({
                        ...paymentData,
                        payment_option: e.target.value,
                      });
                    }}
                  >
                    <FormControlLabel
                      value="cod"
                      control={<Radio />}
                      label="Cash on Delivery"
                    />
                    {/* <FormControlLabel
                      value="card-payment"
                      control={<Radio />}
                      label="Card Payment"
                    /> */}
                  </RadioGroup>
                  <FormHelperText>{errors["payment_option"]}</FormHelperText>
                </FormControl>
              </Box>
              <div className={styles.checkbox}>
                <Checkbox
                  style={{ padding: "1px 0 0 0" }}
                  value={billingData.terms}
                  onChange={(e) =>
                    setBillingData({ ...billingData, terms: e.target.checked })
                  }
                />
                <p>
                  I agree to the{" "}
                  <span
                    className={styles.checboxTerms}
                    onClick={() => navigate("/policies/terms/")}
                  >
                    Terms and Conditions
                  </span>{" "}
                  of Himalayan Computers. By checking this box, I acknowledge
                  and accept the payment, shipping, return, and privacy policies
                  outlined. I am responsible for accurate information and
                  understand the consequences of non-compliance.
                </p>
              </div>
              <FormControl error={!!errors.terms}>
                <FormHelperText>{errors.terms}</FormHelperText>
              </FormControl>
            </form>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          gap="20px"
          m="30px auto"
        >
          <Button
            style={{ position: "relative" }}
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={() => {
              handleFormSubmit();
            }}
            sx={{
              boxShadow: "none",
              color: "white",
              borderRadius: 0,
              padding: "15px 40px",
            }}
          >
            Place Order
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "#52C41A",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
        </Box>
      </Box>
      {paymentModalOpen && (
        <PaymentModal
          open={setPaymentModalOpen}
          onClose={handleClose}
          billingData={billingData}
          paymentData={paymentData}
        />
      )}

      {codModalOpen && (
        <CODModal
          open={setCodModalOpen}
          onClose={handleCodModalClose}
          billingData={billingData}
          paymentData={paymentData}
        />
      )}
    </Box>
  );
};

export default Checkout;
