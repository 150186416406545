import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Slider,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./FilterModal.module.css";

function priceText(value) {
  return `Rs. ${value}`;
}

const FilterModal = ({
  handleBrandToggle,
  handleFilter,
  handleFilterClose,
  selectedBrandIds,
  price,
  handlePriceChange,
  filterOpen,
  availableBrands,
  initialPrice,
}) => {
  const [minPriceInput, setMinPriceInput] = useState(initialPrice[0]);
  const [maxPriceInput, setMaxPriceInput] = useState(
    localStorage.getItem("maxPrice")
  );

  const handleMinPriceInputChange = (event) => {
    const value = event.target.value;
    setMinPriceInput(value);
    handlePriceChange(null, [Number(value), maxPriceInput]);
  };

  const handleMaxPriceInputChange = (event) => {
    const value = event.target.value;
    setMaxPriceInput(value);
    handlePriceChange(null, [minPriceInput, Number(value)]);
  };

  const handleSliderChange = (_, newValues) => {
    setMinPriceInput(newValues[0]);
    setMaxPriceInput(newValues[1]);
    handlePriceChange(null, newValues);
  };
  return (
    <div>
      <Dialog onClose={handleFilterClose} open={filterOpen}>
        <DialogTitle>Custom Filtering</DialogTitle>
        <div className={styles.filterContainer}>
          <div className={styles.priceFilterContainer}>
            <h4 className={styles.priceTitle}>Price Range</h4>
            <p>
              The average price is Rs. {(initialPrice[0] + initialPrice[1]) / 2}
            </p>
            <div className={styles.priceInputs}>
              <TextField
                type="number"
                value={minPriceInput}
                onChange={handleMinPriceInputChange}
                inputProps={{
                  min: minPriceInput,
                  max: localStorage.getItem("maxPrice"),
                  style: { padding: "5px" },
                }}
                className={styles.textfield}
              />
              <span className={styles.priceSeparator}>to</span>
              <TextField
                type="number"
                value={maxPriceInput}
                onChange={handleMaxPriceInputChange}
                inputProps={{
                  min: minPriceInput,
                  max: localStorage.getItem("maxPrice"),
                  style: { padding: "5px" },
                }}
                className={styles.textfield}
              />
            </div>
            <div className={styles.slider}>
              <Slider
                getAriaLabel={() => "Price range"}
                value={price}
                onChange={handleSliderChange}
                valueLabelDisplay="on"
                valueLabelFormat={(value) => `Rs. ${value}`}
                getAriaValueText={priceText}
                min={initialPrice[0]}
                max={initialPrice[1]}
              />
            </div>
          </div>
          {availableBrands.length > 0 && (
            <div className={styles.brandContainer}>
              <h4 className={styles.brandTitle}>Brands</h4>
              {availableBrands?.map((brand) => (
                <FormControlLabel
                  key={brand.id}
                  control={
                    <Checkbox
                      checked={selectedBrandIds.includes(brand.id)}
                      onChange={() => handleBrandToggle(brand.id)}
                    />
                  }
                  label={brand.title}
                />
              ))}
            </div>
          )}
          <div className={styles.btnContainer}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleFilterClose}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleFilter}>
              Apply Filter
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default FilterModal;
