import { baseRequest } from "./base";

const checkoutDisplay = async (orderId, signal) => {
  var response = await baseRequest({
    url: `/api/checkout-display?order_id=${orderId}`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const cardCheckout = async (data) => {
  try {
    const response = await baseRequest({
      url: `/api/checkout`,
      method: "POST",
      body: data,
      contentType: "multipart/form-data",
    });

    return response;
  } catch (error) {
    console.error("Error in cardCheckout:", error);
    throw error;
  }
};

const codCheckout = async (data) => {
  var response = await baseRequest({
    url: `/api/cod-checkout`,
    method: "POST",
    body: data,
  });
  return response;
};

const getPrefectures = async (signal) => {
  var response = await baseRequest({
    url: `/api/prefectures`,
    method: "GET",
    signal: signal,
  });
  return response;
};

export { checkoutDisplay, cardCheckout, codCheckout, getPrefectures };
