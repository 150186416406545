import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductCard from "../../components/ProductCard/ProductCard";
import ProductCardSkeleton from "../../components/ProductCard/ProductCardSkeleton";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getProductCategories, getProducts } from "../../services/api/products";
import styles from "./Products.module.css";

function Products() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    id: "",
    title: "",
  });

  const { width } = useWindowDimensions();

  const navigate = useNavigate();

  useEffect(() => {
    let abort = new AbortController();
    getProductCategories(abort.signal)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {});

    return () => {
      abort.abort();
    };
  }, []);
  useEffect(() => {
    let abort = new AbortController();
    setLoading(true);
    getProducts(selectedCategory.id, abort.signal)
      .then((res) => {
        setProducts(res.data.products);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    return () => {
      abort.abort();
    };
  }, [selectedCategory.id]);

  return (
    <div className={styles.containerFluid}>
      <div className="container">
        <div className={styles.heading}>
          <h2 className={styles.title}>
            {selectedCategory.id === ""
              ? "All Products"
              : selectedCategory.title}
          </h2>
          <button
            className={styles.viewAllBtn}
            onClick={() =>
              navigate("/all-products/", {
                state: {
                  category: "All Products",
                },
              })
            }
          >
            View All <ion-icon name="chevron-forward-outline"></ion-icon>
          </button>
        </div>
        {loading ? (
          <div className={styles.cardContainer}>
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
          </div>
        ) : (
          <div className={styles.cardContainer}>
            {width < 992
              ? products
                  .slice(0, 12)
                  .map((item) => <ProductCard item={item} key={item.id} />)
              : products
                  .slice(0, 20)
                  .map((item) => <ProductCard item={item} key={item.id} />)}
          </div>
        )}
      </div>
    </div>
  );
}

export default Products;
