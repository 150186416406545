import cn from "classnames";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styles from "./Landing.module.css";
import { getHomeItems } from "../../services/api/home";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

function Landing() {
  const [sliderData, setSliderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let abort = new AbortController();
    setLoading(true);
    getHomeItems(abort.signal)
      .then((res) => {
        setSliderData(res.data.sliders);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    return () => {
      abort.abort();
    };
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    dots: true,
    dotsClass: "button__bar",
  };

  return loading ? (
    <div className={styles.container}>
      <div className={cn(styles.bgHeroImg, styles.minHeight, styles.loading)}>
        <CircularProgress />
      </div>
    </div>
  ) : (
    <main className={styles.container}>
      <div className={styles.bgHeroImg}>
        <div className={cn(styles.overflowHidden, styles.minHeight)}>
          <Slider {...settings} className={styles.carousel}>
            {sliderData.map((item, index) => (
              <div key={index}>
                <div className={styles.row}>
                  <div className={styles.rightCol}>
                    <div className={styles.imageContainer}>
                      <img
                        alt="landing"
                        src={item.image}
                        className={styles.image}
                        onClick={() => {
                          item.slider_by === "CATEGORY"
                            ? navigate(
                                `/all-products?title=${item.product_category.title}&category_id=${item.category_id}`
                              )
                            : navigate(`/all-products/${item.product_id}`);
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className={styles.leftCol}>
                      <h2 className={styles.title}>{item.title}</h2>
                      <h5 className={styles.subtitle}>{item.description}</h5>
                      <div className={styles.priceContainer}>
                        <p className={styles.priceTitle}>From</p>
                        <p className={styles.price}>
                          <span>Rs.{item.price}</span>
                        </p>
                      </div>
                      <button
                        className={cn("btn", styles.buyBtn)}
                        onClick={() => {
                          item.slider_by === "CATEGORY"
                            ? navigate(
                                `/all-products?title=${item.product_category.title}&category_id=${item.category_id}`
                              )
                            : navigate(`/all-products/${item.product_id}`);
                        }}
                      >
                        Start Buying
                      </button>
                    </div> */}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </main>
  );
}

export default Landing;
