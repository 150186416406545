import React from "react";
import styles from "./FlashCardSale.module.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";

const FlashSaleCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.content}>
      <div className={styles.imageContainer}>
        <img
          src={data?.image}
          alt="image"
          className={styles.image}
        />
      </div>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Catch Big Deals On the {data.title}</div>
        <div
          className={styles.shop}
          onClick={() =>
            navigate(`/all-products?title=${data.title}&category_id=${data.id}`)
          }
        >
          Shop now
          <span className={styles.iconContainer}>
            <KeyboardArrowRightIcon className={styles.icon} />
          </span>
        </div>
      </div>
    </div>
  );
};
export default FlashSaleCard;
