import { createTheme } from "@mui/material/styles";

export const shades = {
  primary: {
    100: "#1461a1",
    200: "#1461a1",
    300: "#1461a1",
    400: "#1461a1",
    500: "#1461a1",
    600: "#1461a1",
    700: "#1461a1",
    800: "#1461a1",
    900: "#1461a1",
  },
  secondary: {
    100: "#ff4d4f",
    200: "#ff4d4f",
    300: "#ff4d4f",
    400: "#ff4d4f",
    500: "#ff4d4f",
    600: "#ff4d4f",
    700: "#ff4d4f",
    800: "#ff4d4f",
    900: "#ff4d4f",
  },
  neutral: {
    100: "#cdedde",
    200: "#9bdbbd",
    300: "#68c89b",
    400: "#36b67a",
    500: "#04a459",
    600: "#038347",
    700: "#026235",
    800: "#024224",
    900: "#012112",
  },
};

export const theme = createTheme({
  palette: {
    primary: {
      main: "#1461a1",
    },
    secondary: {
      main: "#ff4d4f",
    },
  },
});
