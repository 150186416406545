import React from "react";
import styles from "./ServiceCard.module.css";
import HeadsetIcon from "@mui/icons-material/Headset";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CreditCardIcon from "@mui/icons-material/CreditCard";
const data = [
  {
    icon: <LocalShippingIcon />,
    title: "Free Shipping",
    desc: "Free Shipping for orders",
  },
  {
    icon: <AttachMoneyIcon />,
    title: "Money Guarantee",
    desc: "Within 40 days for an exchange",
  },
  {
    icon: <CreditCardIcon />,
    title: "Flexiable Payment",
    desc: "Pay with Multiple Credit Cards",
  },
  {
    icon: <HeadsetIcon />,
    title: "Online Support",
    desc: "Sun - Fri ( 9:30 - 5:30 )",
  },
];
const ServiceCard = () => {
  return (
    < div className={styles.containerFluid}>
    <div className="container">
      <div className={styles.serviceContainer}>
        <div className={styles.service}>
          {data.map((data) => {
            return (
              <div className={styles.content}>
                <div className={styles.iconContainer}>
                  <div className={styles.icon}>{data.icon}</div>
                </div>
                <div className={styles.data}>
                  <div className={styles.title}>{data.title}</div>
                  <p className={styles.subtitle}>{data.desc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    </div>
  );
};

export default ServiceCard;
