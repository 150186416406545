import cx from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./HeaderDropdown.module.css";

const HeaderDropdown = ({
  item,
  dropdown,
  setDropdownActive,
  setNavActive,
  id,
  title,
}) => {
  const navigate = useNavigate();
  return (
    <ul
      className={cx(
        styles.dropdown,
        styles.dropdownClass,
        dropdown ? styles.show : ""
      )}
    >
      {item.map((menu) => (
        <li
          key={menu.id}
          onClick={(e) => {
            navigate(
              `/all-products?title=${title}&category_id=${id}&product_sub_categories_id=${menu.id}&sub_category_title=${menu.title}`
            );
            setNavActive(false);
            setDropdownActive(false);
            e.stopPropagation();
          }}
          className={styles.menuTitle}
        >
          {menu.title}
        </li>
      ))}
    </ul>
  );
};

export default HeaderDropdown;
