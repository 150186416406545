import { Box, Button } from "@mui/material";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomBreadcrumb } from "../../components/Breadcrumb";
import FilterModal from "../../components/FilterModal/FilterModal";
import { NoSearchResult } from "../../components/NoSearchResult";
import ProductSkeleton from "../../components/Product/ProductSkeleton";
import ProductCard from "../../components/ProductCard/ProductCard";
import { getProducts } from "../../services/api/products";
import styles from "./ShowAll.module.css";

const filterItemTitle = [
  { name: "Recommended First", order: " " },
  { name: "Price, cheapest first", order: "asc" },
  { name: "Price, highest first", order: "desc" },
];

function ShowAllInner() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageSetup, setPageSetup] = useState({
    total: 0,
    current_page: 1,
    last_page: 1,
    page_size: 1,
    to: "",
    from: "",
  });
  const viewProductRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [title, setTitle] = useState(
    searchParams.get("title") || "All Products"
  );
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);

  const [availableBrands, setAvailableBrands] = useState([]);
  const [selectedBrandIds, setSelectedBrandIds] = useState([]);
  const [initialPrice, setInitialPrice] = useState([0, 1000000]);

  const sortRef = useRef();

  const [openFilter, setOpenFilter] = useState(false);
  const [filterTitle, setFilterTitle] = useState({
    name: "Recommended First",
    order: "",
  });

  const [sortOrder, setSortOrder] = useState("");

  const handleBrandToggle = (brandId) => {
    setSelectedBrandIds((prevSelectedBrandIds) => {
      if (prevSelectedBrandIds.includes(brandId)) {
        // Brand is already selected, remove it
        return prevSelectedBrandIds.filter((id) => id !== brandId);
      } else {
        // Brand is not selected, add it
        return [...prevSelectedBrandIds, brandId];
      }
    });
  };

  const handlePriceChange = (event, newValue) => {
    setPrice(newValue);
  };

  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  const handleFilterClose = (value) => {
    setFilterOpen(false);
  };

  const handleFilter = (item) => {
    const newOrderBy = item.order || sortOrder;
    navigate(
      `/all-products?search=${searchParams.get("search") || ""}&min_price=${
        price[0]
      }&max_price=${price[1]}&brand_id=${JSON.stringify(
        selectedBrandIds
      )}&title=${searchParams.get("title") || ""}&category_id=${
        searchParams.get("category_id") || ""
      }&product_sub_categories_id=${
        searchParams.get("product_sub_categories_id") || ""
      }&sub_category_title=${
        searchParams.get("sub_category_title") || ""
      }&order_by=${newOrderBy}`
    );
    setSortOrder(item.order); // Update sortOrder state
    handleFilterClose();
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setOpenFilter(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [sortRef]);

  useEffect(() => {
    let abort = new AbortController();
    if (page > 1) {
      setLoading(true);
      getProducts(
        searchParams.get("category_id") || "",
        searchParams.get("product_sub_categories_id") || "",
        page,
        abort.signal,
        searchParams.get("search") || "",
        searchParams.get("min_price") || "",
        searchParams.get("max_price") || "",
        searchParams.get("brand_id") || ""
      )
        .then((res) => {
          setProducts([...products, ...res.data.products]);
          setPageSetup({
            total: res.data.total,
            current_page: res.data.current_page,
            last_page: res.data.last_page,
            page_size: res.data.page_size,
            to: res.data.to,
            from: res.data.from,
          });
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {
      abort.abort();
    };
  }, [page]);

  useEffect(() => {
    let abort = new AbortController();
    setTitle(searchParams.get("title"));
    setLoading(true);
    getProducts(
      searchParams.get("category_id") || "",
      searchParams.get("product_sub_categories_id") || "",
      1,
      abort.signal,
      searchParams.get("search") || "",
      searchParams.get("min_price") || "",
      searchParams.get("max_price") || "",
      searchParams.get("brand_id") || "",
      searchParams.get("order_by") || ""
    )
      .then(async (res) => {
        setProducts(res.data.products);
        setInitialPrice([res.data.min_price, res.data.max_price]);
        localStorage.setItem("maxPrice", res.data.max_price);
        setPage(1);
        setAvailableBrands(res.data.available_brands);
        if (res.data.category) {
          setTitle(res.data.category.title);
        } else {
          setTitle("All Products");
        }

        setPageSetup({
          total: res.data.total,
          current_page: res.data.current_page,
          last_page: res.data.last_page,
          page_size: res.data.page_size,
          to: res.data.to,
          from: res.data.from,
        });
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false); //error occurs in strict mode due to 2 times api call
      });

    if (searchParams)
      return () => {
        abort.abort();
      };
  }, [searchParams]);

  const [price, setPrice] = useState(initialPrice);

  useEffect(() => {
    let breadcrumbItems = [
      { title: "Home", to: "/" },
      { title: "Products", to: "/all-products/" },
    ];
    const categoryId = searchParams.get("category_id");
    const subCategoryId = searchParams.get("product_sub_categories_id");
    const subCategoryTitle = searchParams.get("sub_category_title");

    if (categoryId) {
      breadcrumbItems.push({
        title: title,
        to: `/all-products?category_id=${categoryId}`,
      });
    }

    if (subCategoryId && subCategoryTitle) {
      breadcrumbItems.push({ title: subCategoryTitle, to: null });
    }
    setBreadcrumb(breadcrumbItems);
  }, [title, searchParams]);

  return (
    <section className={cx("section", styles.product)}>
      <div className="container" ref={viewProductRef}>
        <h2 className={cx("h2", "section-title", styles.sectionTitle)}>
          {title}
        </h2>
        <div className={styles.headerContainer}>
          <CustomBreadcrumb items={breadcrumb} />
          <div className={styles.buttonContainer}>
            <Button
              className={styles.filterButton}
              variant="outlined"
              startIcon={<CiFilter />}
              onClick={handleFilterOpen}
            >
              Filter
            </Button>
            <Button
              className={cx(styles.filterBtnContainer, styles.searchFilterBtn)}
              onClick={() => setOpenFilter((prev) => !prev)}
              variant="outlined"
              ref={sortRef}
            >
              <div className={styles.filterTitle}>{filterTitle.name}</div>

              {openFilter && (
                <div className={styles.filterDropdown}>
                  {filterItemTitle.map((item, index) => (
                    <div
                      className={styles.filterDropdownItem}
                      key={index}
                      onClick={() => {
                        handleFilter(item);
                        setSortOrder(item.order);
                        setFilterTitle({
                          name: item.name,
                          order: item.order,
                        });
                      }}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              )}
            </Button>
          </div>
        </div>
        {searchParams.get("search") && (
          <div>Showing Results for "{searchParams.get("search")}"</div>
        )}

        {pageSetup.total > 0 && (
          <div className={styles.items}>
            {pageSetup.total} items found in storage
          </div>
        )}
        <div className={cx(styles.gridList, "grid-list")}>
          {!(page === 1 && loading) &&
            products.map((item) => <ProductCard item={item} key={item.id} />)}
        </div>
        <div>
          {loading ? (
            <div
              className={cx(styles.gridList, "grid-list")}
              style={{ paddingTop: "10px" }}
            >
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
              <ProductSkeleton />
            </div>
          ) : products.length === 0 ? (
            <>
              <NoSearchResult />
            </>
          ) : (
            pageSetup.current_page !== pageSetup.last_page && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt="40px"
              >
                <button
                  onClick={() => {
                    setPage((page) => page + 1);
                  }}
                  className="outlined-btn"
                >
                  Load More
                </button>
              </Box>
            )
          )}
        </div>
        <FilterModal
          handleBrandToggle={handleBrandToggle}
          handleFilter={handleFilter}
          handleFilterClose={handleFilterClose}
          selectedBrandIds={selectedBrandIds}
          price={price}
          handlePriceChange={handlePriceChange}
          filterOpen={filterOpen}
          availableBrands={availableBrands}
          initialPrice={initialPrice}
        />
      </div>
    </section>
  );
}

function ShowAll() {
  return <ShowAllInner />;
}

export default ShowAll;
