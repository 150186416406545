import React, { useContext } from "react";
import FlashSaleCard from "../../components/FlashCardSale/FlashCardSale";
import styles from "./FlashSale.module.css";
import { StoreContext } from "../../store/store";

const FlashSale = () => {
  const [state] = useContext(StoreContext);
  const { homeState } = state;
  return (
    <div className={styles.containerFluid}>
      <div className="container">
        <div className={styles.cardContainer}>
          {homeState.categories.slice(0, 4).map((data, index) => {
            return <FlashSaleCard data={data} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default FlashSale;
