import { baseRequest } from "./base";

const getAllOrders = async (page, signal) => {
  var response = await baseRequest({
    url: `/api/orders?page=${page}`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const getOrderById = async (id, signal) => {
  var response = await baseRequest({
    url: `/api/orders/${id}`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const getSavedAddress = async (signal) => {
  var response = await baseRequest({
    url: `/api/get-address`,
    method: "GET",
    signal: signal,
  });
  return response;
};

export { getAllOrders, getOrderById, getSavedAddress };
