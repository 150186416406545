import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { theme } from "./theme";
import { StateProvider } from "./store/store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";

const webappRootId = "root";
const webappRootElement = document.getElementById(webappRootId);

if (!webappRootElement) {
  // eslint-disable-next-line no-console
  console.error(`Could not find html element with id '${webappRootId}'`);
} else {
  ReactDOM.createRoot(webappRootElement).render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StateProvider>
        <App />
      </StateProvider>
    </ThemeProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
