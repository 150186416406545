import React, { useRef, useState } from "react";
import styles from "./Uploader.module.css";

const Uploader = ({
  setPhoto,
  error,
  setError,
  icon = true,
  title = " Upload the screenshot of your esewa payment",
}) => {
  const [selectedImage, setSelectedImage] = useState();
  const fileInputRef = useRef(null);

  const handleImageUpload = (event) => {
    const file = event.target.files?.[0] || null;
    setSelectedImage(file);
    if (file) {
      setPhoto(file); // Set the File object directly
      setError(false);
    }
  };

  const handleImageDelete = () => {
    setSelectedImage(null);
    setPhoto("");
  };

  const openFileUploader = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const openImageInNewTab = () => {
    if (selectedImage) {
      const imageUrl = URL.createObjectURL(selectedImage);
      window.open(imageUrl, "_blank");
    }
  };

  return (
    <div>
      <div onClick={openFileUploader} style={{ cursor: "pointer" }}>
        <div className={styles.imageContainer}>
          <div className={styles.uploadContainer}>
            {icon && (
              <img
                src="/images/esewa.png"
                alt="esewa"
                className={styles.image}
                loading="lazy"
              />
            )}
          </div>
          <div className={styles.imageTitleContainer}>
            <div className={styles.imageTitle}>{title}</div>
            <div className={styles.imageSubtitle}>
              (SVG, JPG, PNG, or gif maximum 900x400)
            </div>
          </div>
        </div>
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={fileInputRef}
        style={{ display: "none" }}
        multiple
      />
      {error && <span className={styles.error}>Please upload an image</span>}
      {selectedImage && (
        <div className={styles.imageUploadContainer}>
          <svg
            width="41"
            height="48"
            viewBox="0 0 41 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#a)">
              <path
                opacity=".3"
                d="M27.936 9.848a4.126 4.126 0 0 1-4.123-4.124V0H5.384A5.382 5.382 0 0 0 .002 5.384v32.442a5.382 5.382 0 0 0 5.382 5.384h22.12a5.382 5.382 0 0 0 5.382-5.384V9.85l-4.95-.002Z"
                fill="#00C650"
              />
              <path
                d="M32.886 9.848h-4.95a4.126 4.126 0 0 1-4.123-4.124V0l9.073 9.848ZM36.79 34.12H11.784a3.212 3.212 0 0 0-3.212 3.212v7.456A3.212 3.212 0 0 0 11.783 48H36.79a3.212 3.212 0 0 0 3.212-3.212v-7.456a3.212 3.212 0 0 0-3.212-3.212Z"
                fill="#00C650"
              />
              <path
                d="M16.846 42.674v1.456h-1.505v-1.456h1.505Zm4.47-4.558v4.108c0 .63-.17 1.115-.514 1.454a1.937 1.937 0 0 1-1.426.51 2.156 2.156 0 0 1-1.564-.556c-.385-.372-.578-.905-.58-1.6h1.438c0 .562.214.842.638.842.37 0 .554-.216.554-.646V38.12l1.454-.004Zm2.412 3.904v2.11h-1.454v-6.014h2.356c.714 0 1.258.177 1.632.53a1.88 1.88 0 0 1 .562 1.434 2 2 0 0 1-.25 1 1.738 1.738 0 0 1-.744.688 2.62 2.62 0 0 1-1.2.252h-.902Zm1.616-1.94c0-.533-.292-.8-.876-.8h-.74v1.566h.74c.584.005.876-.25.876-.766Zm6.874-1.48c.485.364.81.9.91 1.498h-1.54a1.174 1.174 0 0 0-.472-.498 1.427 1.427 0 0 0-.758-.2 1.41 1.41 0 0 0-1.104.464 1.8 1.8 0 0 0-.418 1.254c0 .572.146 1.011.438 1.318a1.643 1.643 0 0 0 1.246.46c.327.006.646-.094.91-.286a1.51 1.51 0 0 0 .554-.812h-1.838v-1.046H33.2v1.438c-.116.358-.301.69-.544.978-.262.31-.59.56-.958.732a3.044 3.044 0 0 1-1.33.28 3.167 3.167 0 0 1-1.6-.386 2.642 2.642 0 0 1-1.05-1.086 3.342 3.342 0 0 1-.37-1.6 3.341 3.341 0 0 1 .37-1.59c.237-.455.6-.833 1.046-1.086a3.127 3.127 0 0 1 1.578-.386c.67-.03 1.33.166 1.876.554Z"
                fill="#fff"
              />
              <path
                d="M20.862 29.282h-8.834a3.266 3.266 0 0 1-3.262-3.262v-8.834a3.266 3.266 0 0 1 3.262-3.262h8.834a3.263 3.263 0 0 1 3.26 3.262v8.834a3.265 3.265 0 0 1-3.26 3.262Zm-8.834-13.916a1.822 1.822 0 0 0-1.82 1.82v8.834a1.822 1.822 0 0 0 1.82 1.82h8.834a1.82 1.82 0 0 0 1.818-1.82v-8.834a1.82 1.82 0 0 0-1.818-1.82h-8.834Z"
                fill="#00C650"
              />
              <path
                d="M23.402 24.34v1.68a2.542 2.542 0 0 1-2.54 2.54H12.03a2.54 2.54 0 0 1-2.54-2.54V23.2c1.413-.304 3.567-.4 6.181.644l1.62-1.544 1.105 2.8s.296-1.03 1.252-.884c.956.146 2.504.664 3.166.222a.852.852 0 0 1 .59-.098Zm-3.094-5.35a.982.982 0 1 0 0-1.964.982.982 0 0 0 0 1.964Z"
                fill="#00C650"
              />
            </g>
            <defs>
              <clipPath id="a">
                <path fill="#fff" d="M.002 0h40v48h-40z" />
              </clipPath>
            </defs>
          </svg>
          <div>
            <p onClick={openImageInNewTab} className={styles.imageName}>
              {selectedImage.name}
            </p>
            <p>{(selectedImage.size / (1024 * 1024)).toFixed(2)}MB</p>
          </div>

          <button onClick={handleImageDelete} className={styles.delete}>
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default Uploader;
