import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { default as cn, default as cx } from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaChevronDown, FaPlus } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import { logout } from "../../services/api/auth";
import { StoreContext } from "../../store/store";
import * as userActions from "../../store/user/actionTypes";
import { clearCookie } from "../../utils/cookie/cookies";
import Cart from "../Cart/Cart";
import HeaderDropdown from "../HeaderDropdown/HeaderDropdown";
import Login from "../Login/Login";
import styles from "./Header.module.css";

function Header() {
  const scrollPosition = useScrollPosition();

  const navigate = useNavigate();

  const [state, dispatch] = useContext(StoreContext);
  const { userState, cartState, homeState } = state;
  const [dropdown, setDropdown] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);

  const [cartActive, setCartActive] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [navActive, setNavActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [openCategoryId, setOpenCategoryId] = useState(null);
  const [moreListExpanded, setMoreListExpanded] = useState(false);
  const activeSubcategoryRef = useRef(null);

  const searchRef = useRef(null);
  const menuRef = useRef(null);

  const open = Boolean(anchorEl);

  const handleCategoryClick = (categoryId) => {
    setOpenCategoryId((prev) => (prev === categoryId ? null : categoryId));

    if (activeSubcategoryRef.current) {
      activeSubcategoryRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleProfileClick = (event) => {
    if (userState.isLoggedIn) {
      setAnchorEl(event.currentTarget);
    } else {
      setShowLoginDialog(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    setDropdownActive(false);
    let searchValue = searchRef.current.value;
    if (searchValue.length > 0) {
      navigate(`/all-products?search=${searchValue}`);
    } else {
      navigate("/");
    }
  };

  const handleLogout = () => {
    logout()
      .then((res) => {})
      .catch((err) => {})
      .finally(() => {
        clearCookie("token");
        dispatch({
          type: userActions.LOGGED_OUT,
        });
        toast.success("Logged out Successfully");
        handleClose();
      });
  };

  useEffect(() => {
    const handler = (event) => {
      if (dropdownActive && menuRef.current) {
        if (
          !menuRef.current.contains(event.target) &&
          !event.target.classList.contains(styles.category)
        ) {
          setDropdownActive(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdownActive]);

  return (
    <header>
      <div
        className={cx(
          styles.headerMain,
          scrollPosition > 100 && styles.topActive
        )}
      >
        <div className={cx(styles.container, "container")}>
          <Link to="/" className={cn(styles.logo, styles.desktopLogo)}>
            <img src={"/images/logo-1.png"} width={120} />
          </Link>
          <button
            className={styles.hamburger}
            aria-label="Open Menu"
            onClick={() => setNavActive(true)}
          >
            <ion-icon name="menu-outline"></ion-icon>
          </button>
          <div
            className={styles.headerSearchContainer}
            style={dropdownActive ? { zIndex: 4 } : null}
          >
            <input
              type="text"
              name="search"
              ref={searchRef}
              className={styles.searchField}
              placeholder="Enter your product name..."
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <button className={styles.searchBtn} onClick={handleSearch}>
              <ion-icon name="search-outline"></ion-icon>
            </button>
          </div>
          <div className={styles.headerUserActions}>
            <button
              className={styles.actionBtn}
              title="Support"
              onClick={() => navigate("/support")}
            >
              <ion-icon name="headset"></ion-icon>
            </button>
            <button
              className={styles.actionBtn}
              onClick={() => setCartActive(true)}
            >
              <ion-icon name="bag-handle-outline"></ion-icon>
              {cartState.quantity > 0 && (
                <data className={styles.btnBadge}>{cartState.quantity}</data>
              )}
            </button>
            <button className={styles.actionBtn} onClick={handleProfileClick}>
              <ion-icon name="person-outline"></ion-icon>
            </button>
          </div>
        </div>
        <div className={cx(styles.categories, "container")}>
          <ul className={styles.dropdownPanelList}>
            {homeState.categories.slice(0, 7).map((item) =>
              item.product_sub_categories.length > 0 ? (
                <li
                  key={item.id}
                  className={cx(styles.menuTitle, styles.arrow)}
                  onMouseEnter={() => {
                    setDropdown(true);
                    setActiveCategory(item.id);
                  }}
                  onMouseLeave={() => {
                    setDropdown(false);
                    setActiveCategory(null);
                  }}
                  onClick={() => {
                    navigate(
                      `/all-products?title=${item.title}&category_id=${item.id}`
                    );
                    setActiveCategory(null);
                    setDropdownActive(false);
                  }}
                >
                  <div
                    className={styles.menuItem}
                    aria-expanded={
                      activeCategory === item.id ? "true" : "false"
                    }
                  >
                    {item.title}
                    {activeCategory === item.id && (
                      <div className={styles.dropdown}>
                        <HeaderDropdown
                          key={item.id}
                          item={item.product_sub_categories}
                          dropdown={dropdown}
                          setDropdownActive={setDropdownActive}
                          setNavActive={setNavActive}
                          id={item.id}
                          title={item.title}
                        />
                      </div>
                    )}
                  </div>
                </li>
              ) : (
                <li className={styles.menuTitle}>
                  <div
                    className={styles.menuItem}
                    onClick={() => {
                      setNavActive(false);
                      setDropdownActive(false);
                      navigate(
                        `/all-products?title=${item.title}&category_id=${item.id}`
                      );
                    }}
                  >
                    {item.title}
                  </div>
                </li>
              )
            )}
            <li className={styles.menuTitle}>
              <div
                className={styles.navbarLink}
                onClick={() => setMoreListExpanded(!moreListExpanded)}
              >
                More <FaPlus className={styles.navbarLinkIcon} />
              </div>
              {moreListExpanded && (
                <ul className={styles.dropdownMoreList}>
                  {homeState.categories.slice(7).map((item) =>
                    item.product_sub_categories.length > 0 ? (
                      <li
                        key={item.id}
                        className={cx(styles.menuTitle, styles.arrow)}
                        onMouseEnter={() => {
                          setDropdown(true);
                          setActiveCategory(item.id);
                        }}
                        onMouseLeave={() => {
                          setDropdown(false);
                          setActiveCategory(null);
                        }}
                        onClick={() => {
                          navigate(
                            `/all-products?title=${item.title}&category_id=${item.id}`
                          );
                          setActiveCategory(null);
                          setDropdownActive(false);
                        }}
                      >
                        <div
                          className={styles.menuItem}
                          aria-expanded={
                            activeCategory === item.id ? "true" : "false"
                          }
                          onClick={()=> setMoreListExpanded(false)}
                        >
                          {item.title}
                          {/* {activeCategory === item.id && (
                            <div className={styles.dropdown}>
                              <HeaderDropdown
                                key={item.id}
                                item={item.product_sub_categories}
                                dropdown={dropdown}
                                setDropdownActive={setDropdownActive}
                                setNavActive={setNavActive}
                                id={item.id}
                                title={item.title}
                              />
                            </div>
                          )} */}
                        </div>
                      </li>
                    ) : (
                      <li className={styles.menuTitle}>
                        <div
                          className={styles.menuItem}
                          onClick={() => {
                            setNavActive(false);
                            setDropdownActive(false);
                            navigate(
                              `/all-products?title=${item.title}&category_id=${item.id}`
                            );
                          }}
                        >
                          {item.title}
                        </div>
                      </li>
                    )
                  )}
                </ul>
              )}
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.mobileBottomNavigation}>
        <button
          className={styles.actionBtn}
          onClick={() => navigate("/support")}
        >
          <ion-icon name="headset"></ion-icon>
        </button>
        <button className={styles.actionBtn} onClick={() => navigate("/")}>
          <ion-icon name="home-outline"></ion-icon>
        </button>
        <button
          className={styles.actionBtn}
          onClick={() => setCartActive(true)}
        >
          <ion-icon name="bag-handle-outline"></ion-icon>
          {cartState.quantity > 0 && (
            <data className={styles.btnBadge}>{cartState.quantity}</data>
          )}
        </button>
        <button className={styles.actionBtn} onClick={handleProfileClick}>
          <ion-icon name="person-outline"></ion-icon>
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>{userState.username}</MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("orders");
            }}
          >
            View all Orders
          </MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
      <nav className={cx(styles.navbar, navActive && styles.active)}>
        <div className={styles.sidebarFlex}>
          <Link
            to="/"
            className={styles.mobileLogo}
            onClick={() => setNavActive(false)}
          >
            <img
              src="/images/logo-1.png"
              width={100}
              className={styles.mobileLogoImage}
            />
          </Link>
          <button
            className={styles.navCloseBtn}
            aria-label="Close Menu"
            onClick={() => setNavActive(false)}
          >
            <ion-icon name="close-outline"></ion-icon>
          </button>
        </div>
        <ul className={styles.navbarList}>
          <li className={styles.navTitle}>Categories</li>
          {homeState.categories.map((item, index) => (
            <li key={item.id}>
              <div
                className={styles.navbarLink}
                onClick={() => {
                  if (item?.product_sub_categories.length > 0)
                    handleCategoryClick(item.id);
                  else {
                    navigate(
                      `/all-products?title=${item.title}&category_id=${item.id}`
                    );
                    setNavActive(false);
                    setDropdownActive(false);
                  }
                }}
              >
                {item.title}
                {item?.product_sub_categories.length > 0 && (
                  <FaChevronDown className={styles.navbarLinkIcon} />
                )}
              </div>
              {item?.product_sub_categories.length > 0 && (
                <ul
                  className={cx(
                    styles.submenuCategoryList,
                    openCategoryId === item.id && styles.active
                  )}
                >
                  {item?.product_sub_categories.map((subCategory) => (
                    <li
                      className={styles.submenuCategory}
                      key={subCategory.id}
                      ref={
                        openCategoryId === item.id && index === 0
                          ? activeSubcategoryRef
                          : null
                      }
                      onClick={() => {
                        navigate(
                          `/all-products?title=${item.title}&category_id=${item.id}&product_sub_categories_id=${subCategory.id}&sub_category_title=${subCategory.title}`
                        );
                        setNavActive(false);
                        setDropdownActive(false);
                      }}
                    >
                      <p className={styles.submenuTitle}>
                        {subCategory.title}
                      </p>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <aside className={styles.aside}>
        <div
          className={cx(styles.overlay, cartActive && styles.overlayActive)}
          onClick={() => setCartActive(false)}
        ></div>
        <Cart setCartActive={setCartActive} cartActive={cartActive} />
        {showLoginDialog && (
          <Login open={showLoginDialog} setOpen={setShowLoginDialog} />
        )}
      </aside>
    </header>
  );
}

export default Header;
